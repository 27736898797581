/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import {
  csodAngle,
  colors,
  unitConverter as uc,
  pageDimensions,
} from '../../../../styles/base';
import Section from '../../../molecules/Section/Section';

const GrayBoxBottomSlant = ({ children }) => {
  const sectionCss = css`
    padding: ${uc('40px 0 180px')};
  `;
  const bgCss = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.lightGray.six};
    clip-path: polygon(
      0 0,
      100% 0,
      100%
        calc(
          100% -
            ${csodAngle.heightPercent(pageDimensions.rawLargeDesktopWidth)}vw
        ),
      0 100%
    );
  `;

  return (
    <Section css={sectionCss} className="graybox-bottom-slant">
      <div css={bgCss} />
      {children}
    </Section>
  );
};

GrayBoxBottomSlant.propTypes = {
  children: PropTypes.node,
};

GrayBoxBottomSlant.defaultProps = {
  children: [],
};

export default GrayBoxBottomSlant;
