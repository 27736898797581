/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { colors, unitConverter as uc } from '../../../../styles/base';
import Section from '../../../molecules/Section/Section';

const GrayBox = ({ children }) => {
  const sectionCss = css`
    padding: ${uc('60px 20px')};
    background: ${colors.lightGray.six};
  `;

  return (
    <Section css={sectionCss} className="graybox">
      {children}
    </Section>
  );
};

GrayBox.propTypes = {
  children: PropTypes.node,
};

GrayBox.defaultProps = {
  children: [],
};

export default GrayBox;
