import loadable from '@loadable/component';

const aiBanner = loadable(() =>
  import('../components/organisms/AiBanner/AiBanner')
);
const biographyBlockSection = loadable(() =>
  import('../components/organisms/BiographyBlockSection/BiographyBlockSection')
);
const blockContentSection = loadable(() =>
  import('../components/molecules/BlockContentSection/BlockContentSection')
);
const carouselSection = loadable(() =>
  import('../components/organisms/CarouselSection/CarouselSection')
);
const cccTileSection = loadable(() =>
  import('../components/organisms/CCCTileSection/CCCTileSection')
);
const clientCarouselSection = loadable(() =>
  import('../components/organisms/ClientCarouselSection/ClientCarouselSection')
);
const clientCarouselSectionV1 = loadable(() =>
  import(
    '../components/organisms/ClientCarouselSection/ClientCarouselSectionV1'
  )
);
const clientTileSection = loadable(() =>
  import('../components/organisms/ClientTileSection/ClientTileSection')
);
const customerStatsSection = loadable(() =>
  import('../components/organisms/CustomerStatsSection/CustomerStatsSection')
);
const ctaCardsSection = loadable(() =>
  import('../components/organisms/CtaCardsSection/CtaCardsSection')
);
const form = loadable(() => import('../components/molecules/Form/Form'));
const formSection = loadable(() =>
  import('../components/organisms/FormSection/FormSection')
);
const gradientCard = loadable(() =>
  import('../components/organisms/GradientCard/GradientCard')
);
const gtmSection = loadable(() =>
  import('../components/organisms/GTMSection/GTMSection')
);
const heroBanner = loadable(() =>
  import('../components/organisms/HeroBanner/HeroBanner')
);
const iconGridSection = loadable(() =>
  import('../components/organisms/IconGridSection/IconGridSection')
);
const iconHeadingSection = loadable(() =>
  import('../components/organisms/IconHeadingSection/IconHeadingSection')
);
const iconTextBlockSection = loadable(() =>
  import('../components/organisms/IconTextBlockSection/IconTextBlockSection')
);
const marketoEmbeddedForm = loadable(() =>
  import('../components/molecules/MarketoEmbeddedForm/MarketoEmbeddedForm')
);
const oneColumnSection = loadable(() =>
  import('../components/organisms/OneColumnSection/OneColumnSection')
);
const productSegmentSection = loadable(() =>
  import('../components/organisms/ProductSegmentSection/ProductSegmentSection')
);
const quoteGradientCard = loadable(() =>
  import('../components/organisms/QuoteGradientCard/QuoteGradientCard')
);
const quoteLogoSlider = loadable(() =>
  import('../components/organisms/QuoteLogoSlider/QuoteLogoSlider')
);
const quoteLogoSliderV1 = loadable(() =>
  import('../components/organisms/QuoteLogoSliderV1/QuoteLogoSliderV1')
);
const radialIconSection = loadable(() =>
  import('../components/organisms/RadialIconSection/RadialIconSection')
);
const slantBanner = loadable(() =>
  import('../components/organisms/SlantBanner/SlantBanner')
);
const smbSlantBanner = loadable(() =>
  import('../components/organisms/SmbSlantBanner/SmbSlantBanner')
);
const statsCounterSection = loadable(() =>
  import('../components/organisms/StatsCounterSection/StatsCounterSection')
);
const suiteCards = loadable(() =>
  import('../components/organisms/SuiteCards/SuiteCards')
);
const suiteBenefitsBanner = loadable(() =>
  import('../components/organisms/SuiteBenefitsBanner/SuiteBenefitsBanner')
);
const suiteIconGridSection = loadable(() =>
  import('../components/organisms/SuiteIconGridSection/SuiteIconGridSection')
);
const tilesSection = loadable(() =>
  import('../components/organisms/TilesSection/TilesSection')
);
const timelineSection = loadable(() =>
  import('../components/organisms/TimelineSection/TimelineSection')
);
const threeCardSection = loadable(() =>
  import('../components/organisms/ThreeCardSection/ThreeCardSection')
);
const twoColumnSection = loadable(() =>
  import('../components/organisms/TwoColumnSection/TwoColumnSection')
);
const twoColumnTextSection = loadable(() =>
  import('../components/organisms/TwoColumnTextSection/TwoColumnTextSection')
);

/* Section names must match the Sanity schema name/type. Refer to Section
    field types in backend/schemas/organisms/templates/allClientsPage.js
   */

export const sectionComponents = {
  aiBanner,
  allClientsSlantBanner: slantBanner,
  biographyBlockSection,
  blockContentSection,
  carouselSection,
  cccTileSection,
  clientCarouselSection,
  clientCarouselSectionV1,
  clientSlantBanner: slantBanner,
  clientTileSection,
  customerStatsSection,
  ctaCardsSection,
  form,
  formSection,
  gradientCard,
  gtmSection,
  heroBanner,
  iconGridSection,
  iconHeadingSection,
  iconTextBlockSection,
  industrySlantBanner: slantBanner,
  marketoEmbeddedForm,
  oneColumnSection,
  productSegmentSection,
  quoteGradientCard,
  quoteLogoSlider,
  quoteLogoSliderV1,
  radialIconSection,
  slantBanner,
  statsCounterSection,
  smbSlantBanner,
  subscriptionSlantBanner: slantBanner,
  suiteBenefitsBanner,
  suiteCards,
  suiteIconGridSection,
  tilesSection,
  threeCardSection,
  timelineSection,
  twoColumnSection,
  twoColumnTextSection,
};

export default {
  sectionComponents,
};
