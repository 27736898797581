/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import {
  csodAngle,
  colors,
  pageDimensions,
  unitConverter as uc,
} from '../../../../styles/base';
import Section from '../../../molecules/Section/Section';

const GrayBoxTopSlant = ({ children }) => {
  const sectionCss = css`
    padding: ${uc('40px 0 180px')};
  `;
  const heightPercent = csodAngle.heightPercent(
    pageDimensions.rawLargeDesktopWidth
  );
  const bgCss = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.lightGray.six};
    clip-path: polygon(
      0 calc(0% + ${heightPercent}vw),
      100% 0,
      100% 100%,
      0 100%
    );
  `;

  return (
    <Section css={sectionCss} className="graybox-top-slant">
      <div css={bgCss} />
      {children}
    </Section>
  );
};

GrayBoxTopSlant.propTypes = {
  children: PropTypes.node,
};

GrayBoxTopSlant.defaultProps = {
  children: [],
};

export default GrayBoxTopSlant;
