import customBackground from './Backgrounds/CustomBackground/CustomBackground';
import grayBox from './Backgrounds/GrayBox/GrayBox';
import grayBoxBottomSlant from './Backgrounds/GrayBoxBottomSlant/GrayBoxBottomSlant';
import grayBoxTopSlant from './Backgrounds/GrayBoxTopSlant/GrayBoxTopSlant';
import grayBoxDoubleSlant from './Backgrounds/GrayBoxDoubleSlant/GrayBoxDoubleSlant';
import grayCurves from './Backgrounds/GrayCurves/GrayCurves';
import noBackground from './Backgrounds/NoBackground/NoBackground';

export default {
  customBackground,
  grayBox,
  grayBoxBottomSlant,
  grayBoxTopSlant,
  grayBoxDoubleSlant,
  grayCurves,
  noBackground,
};
