/**
 * GrayCurves refers to the section that starts with 2 gray curves and
 * ends with 2 gray curves and has a gray background in between. See the
 * ./assets/curved-background.png for reference
 */

/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import Section from '../../../molecules/Section/Section';
import { colors, unitConverter as uc } from '../../../../styles/base';

// https://css-tricks.com/gradient-borders-in-css/
// adjusted this linear gradient to match the curves background image
const sectionCss = css`
  z-index: 1;
  overflow: hidden;
  background: linear-gradient(
    to right,
    #ebf3f9,
    #ebf3f9 29%,
    #e5eff7 64%,
    #e5eff6
  );
`;

const border = uc('300px');

const curvesCss = css`
  position: absolute;
  right: ${uc('-3203px')};
  bottom: 0;
  box-sizing: border-box;
  width: ${uc('10000px')};
  height: ${uc('10000px')};
  margin: auto;

  color: ${colors.white};
  background: ${colors.white};
  background-clip: padding-box;
  border: solid ${border} transparent;
  border-radius: calc(${border} * 18);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: calc(-0.5 * ${border});
    background: linear-gradient(
      to left,
      #f5f9fc,
      #f5f9fc 31%,
      ${colors.white} 40%,
      ${colors.white}
    );
    border-radius: inherit;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -${border};
    border: calc(${border} / 2) solid #f5f9fc;
    border-radius: inherit;
  }
`;

const GrayCurves = ({ children }) => {
  return (
    <Section css={sectionCss}>
      <div css={curvesCss} />
      {children}
    </Section>
  );
};

GrayCurves.propTypes = {
  children: PropTypes.node,
};

GrayCurves.defaultProps = {
  children: [],
};

export default GrayCurves;
