/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import {
  breakpoints,
  pageDimensions,
  sectionSettings,
  unitConverter as uc,
} from '../../../../styles/base';
import Section from '../../../molecules/Section/Section';
import sanityImage from '../../../../utils/sanityImage';

const CustomBackground = ({
  children,
  desktopBgImage,
  desktopStyles,
  mobileBgImage,
  mobileStyles,
  tabletBgImage,
  tabletStyles,
}) => {
  const getBgStyles = stylesProps => {
    let marginTop = 'initial';
    if (stylesProps.marginTop && stylesProps.marginTopPercentage) {
      marginTop = `${stylesProps.marginTop}%`;
    } else if (stylesProps.marginTop) {
      marginTop = uc(`${stylesProps.marginTop}px`);
    }
    return css`
      margin-top: ${marginTop};
      margin-bottom: ${stylesProps.marginBottom
        ? uc(`${stylesProps.marginBottom}px`)
        : 'initial'};
      padding-top: ${stylesProps.paddingTop
        ? sectionSettings[stylesProps.paddingTop]
        : 'initial'};
      padding-bottom: ${stylesProps.paddingBottom
        ? sectionSettings[stylesProps.paddingBottom]
        : 'initial'};
      background-color: ${stylesProps.backgroundColor
        ? sectionSettings[stylesProps.backgroundColor]
        : 'initial'};
      background-position-x: center;
      background-position-y: ${stylesProps.backgroundPositionY
        ? uc(`${stylesProps.backgroundPositionY}px`)
        : 0};
    `;
  };

  const sectionCss = css`
    min-height: ${uc('800px')};
    background-image: url(${sanityImage(desktopBgImage)
      .width(pageDimensions.rawLargeDesktopWidth)
      .fit('max')
      .auto('format')
      .url()});
    background-repeat: no-repeat;
    background-size: ${pageDimensions.largeDesktopWidth};
    ${getBgStyles(desktopStyles)}
  `;

  const tabletBgImageCss =
    Object.keys(tabletBgImage).length !== 0
      ? css`
          @media (${breakpoints.tablet}) {
            background-image: url(${sanityImage(tabletBgImage)
              .width(pageDimensions.tabletWidth)
              .auto('format')
              .url()});
            ${getBgStyles(tabletStyles)}
          }
        `
      : '';

  const mobileBgImageCss =
    Object.keys(mobileBgImage).length !== 0
      ? css`
          @media (${breakpoints.mobile}) {
            background-image: url(${sanityImage(mobileBgImage)
              .width(pageDimensions.mobileWidth)
              .auto('format')
              .url()});
            ${getBgStyles(mobileStyles)}
          }
        `
      : '';

  const combinedSectionCss = [sectionCss, tabletBgImageCss, mobileBgImageCss];

  if (children.length > 1) {
    return (
      <Section className="custom-background" css={combinedSectionCss}>
        {children}
      </Section>
    );
  }

  return <Section css={combinedSectionCss}>{children}</Section>;
};

CustomBackground.propTypes = {
  children: PropTypes.node,
  desktopBgImage: PropTypes.shape({}).isRequired,
  desktopStyles: PropTypes.shape({}),
  mobileBgImage: PropTypes.shape({}),
  mobileStyles: PropTypes.shape({}),
  tabletBgImage: PropTypes.shape({}),
  tabletStyles: PropTypes.shape({}),
};

CustomBackground.defaultProps = {
  children: [],
  desktopStyles: {},
  mobileBgImage: {},
  mobileStyles: {},
  tabletBgImage: {},
  tabletStyles: {},
};

export default CustomBackground;
