/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { colors, csodAngle, pageDimensions } from '../../../../styles/base';
import Section from '../../../molecules/Section/Section';

const GrayBoxDoubleSlant = ({ children }) => {
  const heightPercent = csodAngle.heightPercent(
    pageDimensions.rawLargeDesktopWidth
  );

  const sectionCss = css`
    padding: ${heightPercent}vw 0 ${heightPercent / 2}vw;
  `;

  const bgCss = css`
    display: grid;
  `;

  const bgTopCss = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${heightPercent}vw;
    background: ${colors.lightGray.six};
    clip-path: polygon(0 ${heightPercent}vw, 100% 0, 100% 100%, 0 100%);
  `;
  const bgMiddleCss = css`
    position: absolute;
    top: ${heightPercent}vw;
    left: 0;
    width: 100%;
    height: calc(100% - ${heightPercent * 2}vw);
    background: ${colors.lightGray.six};
  `;
  const bgBottomCss = css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${heightPercent}vw;
    background: ${colors.lightGray.six};
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - ${heightPercent}vw),
      0 100%
    );
  `;

  // ((1920 - 1390) / 2) + 1390
  // twocolumnsection needs a top 120px on the text content

  const tweakedSectionCss = children.map(child => {
    switch (child.props._type) {
      case 'twoColumnSection':
        if (child.props.rightBlock.columnBlocks[0]._type === 'textBlock') {
          return css`
            .text-block-wrapper {
              transform: translateY(-${heightPercent / 2}vw);
            }
          `;
        }
        break;

      default:
        return '';
    }
    return '';
  });

  return (
    <Section
      css={[sectionCss, tweakedSectionCss]}
      className="graybox-double-slant"
    >
      <div css={bgCss}>
        <div css={bgTopCss} />
        <div css={bgMiddleCss} />
        <div css={bgBottomCss} />
      </div>

      {children}
    </Section>
  );
};

GrayBoxDoubleSlant.propTypes = {
  children: PropTypes.node,
};

GrayBoxDoubleSlant.defaultProps = {
  children: [],
};

export default GrayBoxDoubleSlant;
