/** @jsx jsx this comment is required for Storybook to render */
import { Fragment } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

const NoBackground = ({ children }) => {
  return <Fragment>{children}</Fragment>;
};

NoBackground.propTypes = {
  children: PropTypes.node,
};

NoBackground.defaultProps = {
  children: [],
};
export default NoBackground;
